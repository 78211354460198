//Scroll

/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888888ad;
    border-radius: 10px;
}  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}