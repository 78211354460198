//@import 'src/app/shared/styles/buttons';
@import './colors';

.btn-primary-color {
  color: $white;
  background-color: $primary-color;
  &:hover{
    background-color: rgba($primary-color, 0.7);
  }
}

.btn-secundary-color {
  color: $white;
  background-color: $secundary-color;
  &:hover{
    background-color: rgba($secundary-color, 0.7);
  }
}

.btn-terciary-color {
  color: $white;
  background-color: $terciary-color;
  &:hover{
    background-color: rgba($terciary-color, 0.7);
  }
}

.btnl-primary-color {
  background-color: $white;
  color: $primary-color;
  border: 1px solid $primary-color;
  &:hover {
    background-color: rgba($primary-color, 1);
    color: $white;
  }
}

.btnl-secundary-color {
  background-color: $white;
  color: $secundary-color;
  border: 1px solid $secundary-color;
  &:hover {
    background-color: rgba($secundary-color, 1);
    color: $white;
  }
}

.btnl-terciary-color {
  background-color: $white;
  color: $terciary-color;
  border: 1px solid $terciary-color;
  &:hover {
    background-color: rgba($terciary-color, 1);
    color: $white;
  }
}

.btnl-support-color {
  color: $support-color;
  border: 1px solid $support-color;
  &:hover {
    background-color: rgba($primary-color, 1);
    color: $white;
    border: 1px solid transparent;
  }
}

.btn-acoes {
  color: $text-medio;
  &:hover {
    color: $primary-color;
  }
  & i {
    color: $text-medio;
    &:hover {
      color: $primary-color;
    }
  }
}
  