/* ====== MENU CSS DAFAULT  ====== *//*

  1 - Border - Default Brada
  2 - Icons
  3 - Maks
  4 - Cards
  5 - Slide Case
  6 - Mobile Functions
  7 - Modal
  8 - Forms
  9 - Breadcrumb
  10 - Nav & Tabs
  11 - Erro Toast
  12 - Img Overlay

*//* ====== MENU CSS DAFAULT  ====== */

@import './buttons';
@import './colors';
@import './fonts';
@import './scroll';
@import './ngx-editor';

body  { color: $support-color; background-color: $body-background; }
a     { cursor: pointer; }

.tm-select { min-height: 50px; }
.tm-input  { height: 50px; }

.required::after { content: "*"; }

.form-control:disabled,
.ng-select.ng-select-disabled>.ng-select-container {
  background-color: rgba($text-claro, 0.2);
  border: 1px solid $text-claro;

  & .ng-arrow-wrapper {
    display: none;
  }
}



// 1 - Border - Default Brada
.border-brada { border-radius: 0 0.5rem 0.5rem 0.5rem; }
.border-brada-5 { border-radius: 0 2.5rem 2.5rem 2.5rem; }
.border-brada-4 { border-radius: 0 1rem 1rem 1rem; }
.border-5 { border-radius: 1rem; }
.border-4 { border-radius: 0.7rem; }

.radius-start-3 { border-radius: 0.5rem 0 0 0.5rem; }
.radius-end-3 { border-radius: 0 0.5rem 0.5rem 0; }


// 2 - Icons
.bi-circle-fill { font-size: 0.4rem; }

.cls-1 {
  fill: none;
  stroke: $primary-color;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.icon-border {
width: 64px;
height: 64px;
background-color: transparent;
border: 2px solid $primary-color;
border-radius: 50% 50%;
padding: 18px 17px;
margin: auto;
  & img {
    height: 20px;
  }
}


// 3 - Maks
$size-mask: 55vh;
$size-mask-right: 40vh;
$size-mask-left: 40vh;

.mask-logo {
  width: $size-mask;
  height: $size-mask;
  padding: 1rem;

  background-image: url(https://images.unsplash.com/photo-1528287942171-fbe365d1d9ac?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&w=1200&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  mask-image: url("/assets/image/mask/item-marca-vector.svg");
  mask-size: $size-mask;
  mask-repeat: no-repeat;
  mask-position: center;
}

.mask-logo-right {
  width: $size-mask-right;
  height: $size-mask-right;
  padding: 1rem;

  background-image: url(https://images.unsplash.com/photo-1528287942171-fbe365d1d9ac?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&w=1200&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  mask-image: url("/assets/image/mask/item-marca-vector-right.svg");
  mask-size: $size-mask-right;
  mask-repeat: no-repeat;
  mask-position: center;

  margin-left: -30vh;
  margin-top: -15vh;
}

.mask-left {
  & .mask-logo {
    margin-left: 12vh;
    margin-top: -6vh;
  }
  & .mask-logo-left {
    width: $size-mask-left;
    height: $size-mask-left;
    padding: 1rem;
  
    background-image: url(https://images.unsplash.com/photo-1528287942171-fbe365d1d9ac?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&w=1200&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  
    mask-image: url("/assets/image/mask/item-marca-vector-left.svg");
    mask-size: $size-mask-left;
    mask-repeat: no-repeat;
    mask-position: center;
  
    margin-top: -27vh;
  }
}


// 4 - Cards
.cards {
  & .card {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 0;
    margin: 0;
    border: 0px solid transparent;
    border-radius: 0.7rem;
  
    & .card-body {
      color: $white;
      background: rgba($text-escuro, 0.90);
      border-radius: 0.8rem;
      padding: 4.5rem 0 1rem 0 !important;
      transition: all .5s;
  
      & i {
        color: transparent;
        transition: all .5s;
      }
  
      &:hover {
        background: rgba($primary-color, 0.5);
        cursor: pointer;
        padding: 3rem 0 2.5rem 0 !important;
        transition: all .5s;
        & i {
          color: $white;
          transition: all .5s;
        }
      }
    }
  }

  & .card-blue { 
    & .card-body {
      &:hover {
          background: rgba($secundary-color, 0.5);
      }
    }
  }

  & .card-img-overlay { background-color: rgba($primary-color, 0.15); }
}

.accordion > .card > .card-body {
  background-color: #fff !important;
}


// 5 - Slide Case
.buttons-slide {
  & > a {
    display: inline-block;
    width:       0.8rem;
    height:      0.8rem;
    line-height: 0.8rem;
    border-radius: 0.8rem;
    text-decoration: none;
    text-align: center;
    background: rgba($primary-color, 0.4);
    -webkit-transition: 0.3s;
            transition: 0.3s;
    margin: 0 0.2rem;
    cursor: pointer;

    &:hover {
      background: rgba($primary-color, 1);
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }
  & > .active {
    background: rgba($primary-color, 1);
  }
}

// 6 - Mobile Functions
@media only screen and (max-width: 500px) {
  
  .scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin-bottom: 20px;
    margin: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }

}

@media only screen and (min-device-width: 1400px) {
  .bg-overlay { height: 100%; min-height: 100vh; }
}

// 7 - Modal
.modal-content {
  border: none;
  //border-radius: 0.5rem 0.5rem;
  border-radius: 0 1.5rem 1.5rem 1.5rem !important;

  & .modal-header, .modal-footer {
    border: none;
  }

  & .modal-header {
    padding: 0px !important;
    border: 0px;
    & .btn-close {
      margin: 0px;
      padding: 15px;
      border-radius: 0rem 1rem 0rem 0.5rem;
      opacity: 1;
      background-color: $primary-color;
        &:hover {
          background-color: rgba($primary-color, 0.7);
        }
    }
  }  
}

.modal-backdrop.fade.show {
  background-color: $support-color;
  opacity: 0.93;
}


// 8 - Forms
textarea { min-height: 6rem; }




// 9 - Breadcrumb
.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: .5rem;
  color: $primary-color;
  content: var(--bs-breadcrumb-divider, "›");
}

// 10 - Nav & Tabs
.nav-tabs {
  border: none;
  background-color: $body-background;
  color: $white;

  & .active {
      background-color: $primary-color;
      color: $white;
      border-radius: 0.7rem 0.7rem 0 0;
      
      &:hover { color: $white; }
  }

  & .nav-link {


  }
}

.content-nav { background-color: transparent !important; }


// 11 - Erro Toast

.toast-container {
  margin-top: 2rem;
  font-size: 0.8rem;
  & .toast-error {
    background-size: 1rem !important;
  }
  & .ngx-toastr {
    box-shadow: none !important;
  }
} 


// 12 - Img Overlay

.bg-overlay-sections {
  background: url("/assets/image/bg-banner-principal.png");
  background-repeat: repeat;
  background-size: cover;
  background-position: center center;
  padding: 0;
  margin: 0;
}
  

// &:hover {
//   transition: all .5s;
//   transform: scale(1.2);
// }

// linear-gradient(rgba($support-color, 0.5),rgba($support-color, 0.5)), url(https://images.unsplash.com/photo-1528287942171-fbe365d1d9ac?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&w=1200&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ);

// @media only screen and (min-device-width: 0px) and (max-device-width: 1399px) {
//   .bg-overlay { height: 100%; }
// }

@media only screen and (max-width: 500px) {
  .modal-content { 
    width: 90% !important;
    margin: 0 5%;
  }
}