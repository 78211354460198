@import './colors';

.fw-100 { font-weight: 100; }
.fw-200 { font-weight: 200; }
.fw-300 { font-weight: 300; }
.fw-400 { font-weight: 400; }
.fw-500 { font-weight: 500; }
.fw-600 { font-weight: 600; }
.fw-700 { font-weight: 700; }
.fw-800 { font-weight: 800; }
.fw-900 { font-weight: 900; }

.h7 { font-size: 12px; }


.title-line {
    color: $primary-color;
    &::after {
      content: '';
      display: block;
      height: 4px;
      border-radius: 2px 2px;
      background: $primary-color;
      margin: 0.5rem 0 0 0;
    }
}

.title-line2 {
  color: $black;
  &::after {
    content: '';
    display: block;
    height: 4px;
    border-radius: 2px 2px;
    background: $primary-color;
    margin: 0.5rem 0 0 0;
  }
}