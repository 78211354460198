
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$brada-app-primary: mat.define-palette(mat.$indigo-palette);
$brada-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$brada-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$brada-app-theme: mat.define-light-theme((
  color: (
    primary: $brada-app-primary,
    accent: $brada-app-accent,
    warn: $brada-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($brada-app-theme);

/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/scss/bootstrap';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');
@import "~@ng-select/ng-select/themes/default.theme.css";

// styles scss
@import './app/shared/styles/default';

body {
  font-family: 'Poppins', sans-serif !important;
  background-color: #F4F4F8;
}

// NgSelect Multiple
.ng-select-container {
    min-height: 50px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 12px !important;
}

.ng-select .ng-select-container .ng-value-container {
  display: flex;
  flex: 1;
  max-height: 100px;
  overflow-y: auto;
  margin: 0.5rem 1.5rem 0.5rem 0.5rem;
}

// Erro Input
.invalid-feedback {
  top: 39px !important;
  right: 5px !important;
  color: #e4002b !important;
  z-index: 0 !important;
}

.NgxEditor__MenuBar { overflow-wrap: auto; }

span.sr-only { display: none; }
.page-item.active .page-link { background-color: $primary-color !important; border: 1px solid $primary-color !important; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.tm-select { min-height: 50px; }
.tm-input  { height: 50px; }

.form-control {
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    opacity: 1;
  }
}

.angular-editor-toolbar {
  background-color: #ffffff !important;
  border: 0px solid !important;
  padding: 0.5rem 0.5rem 0rem 0.5rem !important;
}

.angular-editor-wrapper {
  padding: 0.0rem 0.5rem 0.5rem 0.5rem !important;
}

.angular-editor-textarea {
  background-color: #ffffff !important;
}

