$red: #ff0023;
$blue: #2524cc;
$orange: #f96500;
$white: #ffffff;
$black: #25242d;
$yellow: #FEC115;
$green: #B3D431;

$body-background:   #F4F4F8;

$text-escuro:       #404040;
$text-medio:        #707070;
$text-claro:        #e6e6e6;

$primary-color:     $red;
$secundary-color:   $blue;
$terciary-color:    $orange;
$support-color:     $text-escuro;

.bg-body-color      { background-color: $body-background; };
.bg-primary-color   { background-color: $primary-color; };
.bg-secundary-color { background-color: $secundary-color; };
.bg-terciary-color  { background-color: $terciary-color; };
.bg-white-color     { background-color: $white; };
.bg-claro-color     { background-color: $text-claro; };
.bg-medio-color     { background-color: $text-medio; };
.bg-green-color     { background-color: #d2f4ea; };

.bg-primary-color-t5 { background-color: rgba($primary-color, 0.8); };
.bg-white-color-t2   { background-color: rgba($white, 0.3); };
.bg-white-color-t8   { background-color: rgba($white, 0.8); };
.bg-white-color-t98  { background-color: rgba($white, 0.98); };

.txt-primary-color   { color: $primary-color; };
.txt-secundary-color { color: $secundary-color; };
.txt-terciary-color  { color: $terciary-color; };
.txt-support-color   { color: $support-color; };
.txt-white           { color: $white; };

.txt-yellow-color   { color: $yellow; };
.txt-green-color    { color: $green; };

.txt-escuro-color   { color: $black; };
.txt-medio-color    { color: $text-medio; };
.txt-claro-color    { color: $text-claro; };

.border-body-color      { border: 1px solid $body-background; };
.border-primary-color   { border: 1px solid $primary-color; };
.border-secundary-color { border: 1px solid $secundary-color; };
.border-terciary-color  { border: 1px solid $terciary-color; };
.border-white-color     { border: 1px solid $white; };
.border-claro-color     { border: 1px solid $text-claro; };
.border-medio-color     { border: 1px solid $text-medio; };
